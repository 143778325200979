const news = document.getElementById("newsArea");
const info = document.getElementById("about");

function showNews() {
  newsArea.classList.remove("hidden");
  info.classList.add("hidden");
}

function showInfo() {
  newsArea.classList.add("hidden");
  info.classList.remove("hidden");
}
